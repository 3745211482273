import { Dialog as HUIDialog, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react';
import { cn } from '@ramdam/ui/lib/utils';
import cx from 'classnames';

import { Icon } from './Icon';

type DialogSize = 'md' | 'lg';

export interface DialogProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'role'> {
  isOpen: boolean;
  onClose: () => void;
  size?: DialogSize;
  panelWrapperClassName?: string;
  children: React.ReactNode;
}

interface DialogHeaderProps {
  children: React.ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
}

interface DialogContentProps {
  children: React.ReactNode;
}

interface DialogFooterProps {
  children: React.ReactNode;
  className?: string;
}

export function Dialog({ isOpen, onClose, size = 'md', children, panelWrapperClassName, ...rest }: DialogProps) {
  return (
    <HUIDialog open={isOpen} onClose={onClose} className="relative z-40" {...rest}>
      <DialogBackdrop className="fixed inset-0 z-40 bg-black/30" />

      <div className={cn('fixed inset-0 p-4 z-50 flex items-center justify-center', panelWrapperClassName)}>
        <DialogPanel className={cx('rounded-2xl bg-white w-full', dialogPanelClassesBySize[size])}>
          <div className="flex h-full flex-col">{children}</div>
        </DialogPanel>
      </div>
    </HUIDialog>
  );
}

const dialogPanelClassesBySize: Record<DialogSize, string> = {
  md: 'max-w-[500px]',
  lg: 'max-w-5xl',
};

export function DialogHeader({ children, onClose, showCloseButton = true }: DialogHeaderProps) {
  return (
    <div className="flex items-center justify-between px-4 pt-4">
      <DialogTitle className="text-l-bold">{children}</DialogTitle>
      {showCloseButton && onClose && (
        <button onClick={onClose} aria-label="Close dialog" type="button">
          <Icon name="close" className="text-[20px]" />
        </button>
      )}
    </div>
  );
}

export function DialogContent({ children }: DialogContentProps) {
  return <div className="flex-1 overflow-y-auto p-4">{children}</div>;
}

export function DialogFooter({ children, className }: DialogFooterProps) {
  return <div className={cx('flex gap-3 border-t border-initial p-4', className)}>{children}</div>;
}
